<template>
  <div class="ps-section__right">
    <form class="ps-form--account-setting" method="post">
      <div class="ps-form__header">
        <h3>Create Shop Hours</h3>
      </div>
      <HoursForm ref="addressForm"></HoursForm>
    </form>

  </div>
</template>

<script>
import HoursForm from "./HoursForm";

export default {
  name: "CreateShopHours",
  components: {HoursForm},
}
</script>

<style scoped>

</style>